.service_banner {
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.service_main {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.service_main .nav-link,
.portfolio-block .nav-link.active {
  border: 2px solid #211d32;
  border-radius: 10px;
  padding: 10px 30px;
  cursor: pointer;
  margin: 0 20px;
  color: black;
}
.service_main .nav-link.active {
  background-color: #805dcd;
  color: #ffffff;
  border-color: #805dcd;
}
.service_content p {
  color: #212529;
  font-size: 17px;
  line-height: 1.5;
  text-align: justify;
}
.service_content h3 {
  color: #212529;
  font-weight: 600;
}
