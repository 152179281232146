.about_banner {
  background-size: cover;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about_text h2 {
  font-size: 38px;
  font-weight: 700;
}
.about_text p {
  margin: 20px 0px;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 500;
}
.about_text button {
  padding: 15px 35px;
  border: none;
  background-image: radial-gradient(
    circle farthest-corner at 100% 150%,
    #d75dbc 10%,
    #0a3ca3 50%
  );
  border-radius: 40px;
  color: white;
  transition: 0.6s;
}
.about_text button:hover {
  background-image: radial-gradient(
    circle farthest-corner at 100% 150%,
    white 10%,
    white 50%
  );
  border: 2px solid #6a4bc4;
  color: black;
}
.icons_style img {
  background: radial-gradient(
    circle farthest-corner at 100% 150%,
    #d75dbc 10%,
    #0a3ca3 50%
  );
  padding: 10px;
  border-radius: 50%;
}
.about_main {
  margin-top: 80px;
}
.about_main h3 {
  text-align: center;
  color: black;
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
}
.service_data {
  gap: 10px;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  padding: 30px 30px;
  box-shadow: 0px 15px 38px 0px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 30px;
  justify-content: flex-start;
  transition: 0.6s;
  cursor: pointer;
}
.service_data h3 {
  font-size: 18px;
  font-weight: 700;
  color: black;
}
.service_data:hover {
  transform: translateY(-16px);
}
