.footer_main {
  padding: 50px 10px;
  background-color: #211d32;
  color: white;
}
.social_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.social_icon:hover {
  background-color: #805dcd;
  color: white;
}
