.main_info {
  width: 100%;
  background-color: #805dcd;
  color: white;
  padding: 20px 0px;
}
.inner-content {
  text-align: center;
}
.section_border{
    border-right: 2px solid #FFFFFF45;
    
}