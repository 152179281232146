.project_main {
  cursor: pointer;
  transition: 0.5s;
  max-height: 250px;
  height: 100%;
  margin: 20px 0px;
  border: 2px solid rgb(110, 108, 108);
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.project_main:hover {
  /* border: 2px solid #805DCD; */
  box-shadow: 5px 10px 10px #805dcd;
}
.caseStudiesProject p {
  font-size: 18px;
  font-weight: 600;
}
.project_main img {
  border-radius: 20px;
}

.caseStudiesBg {
  background-size: cover;
  height: 400px;
  background-position: center;
}

.project_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.project_text h3 {
  color: white;
  font-size: 40px;
  font-weight: 400;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
