.bg_banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.btn-explore {
  background-color: #805dcd;
  color: white;
  padding: 16px 24px;
  border: none;
  transition: 0.8s;
}
.btn-explore:hover {
  background-color: #211d32;
}
