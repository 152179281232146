.product_head {
  text-align: center;
}
.product_head p {
  font-size: 18px;
  background-color: #d8cdf0;
  color: #3b364e;
  display: inline-block;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 50px;
}
.product_head h3 {
  font-size: 48px;
  font-weight: 400;
  color: #211d32;
}
.bgcolor{
  background-color: #F8F8F8;
  padding: 40px 0px;
}