.service_head {
  text-align: center;
}
.service_head p {
  font-size: 18px;
  background-color: #d8cdf0;
  color: #3b364e;
  display: inline-block;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 50px;
}
.service_head h3 {
  font-size: 48px;
  font-weight: 400;
  color: #211d32;
}
.service-text h5{
  font-size: 22px;
  font-weight: 600;
}
.service-text p{
  font-size: 16px;
  font-weight: 400;
}
.service-text a {
  text-decoration: none;
  color: #c7c6ca;
}
.main_services {
  padding: 30px 20px;
}
.main_services {
  transition: 0.8s;
}
.main_services:hover {
  transform: translateY(-10px);
  border-radius: 16px;
  background-color: #211d32;
  color: white;
}
.main_services:hover .service-text a {
  background-color: #805dcd;
  padding: 6px 15px;
  border-radius: 10px;
  color: #fefefe;
}
