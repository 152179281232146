.card_main {
  /* border: 2px solid red; */
  transition: .8s;
  display: flex;
  flex-wrap: wrap;
}
.btn-explore {
  background-color: #805dcd;
  color: white;
  border: none;
  padding: 10px 16px;
  margin: 16px 0px;
}
.product-titles {
  background-color: whitesmoke;
}
.product-titles h5 {
  color: #333;
  font-weight: 600;
  padding: 10px 16px;
}
.product-titles p {
  color: #696969;
  font-size: 16px;
  padding: 10px 16px;
}
.card_main:hover{
    transform: translateY(-20px);
}