.navbar-collapse {
    flex-grow: 0
}

.bg-body-tertiary {
    height: 80px;
}

.navbar-expand-lg .navbar-nav {
    gap: 16px;
}

.offcanvas-header {
    background-color: #ffffff;
}

.offcanvas {
    background-color: #211D32;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 4px 10px;
    color: white;
}

@media only screen and (min-width: 992px) {
    .nav-link.active {
        border: 2px solid white;
        border-radius: 50px;
        padding: 4px 10px;
    }
}

@media only screen and (max-width: 767px) {
    .nav-link {
        color: white;
        padding: 16px 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        line-height: 2;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .nav-link {
        color: white;
        padding: 16px 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        line-height: 2;
    }
}
.loader {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  