.contact_banner {
  background-size: cover;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_form textarea {
  width: 100%;
}
.contact_form button {
  background-color: #805dcd;
  color: white;
  padding: 10px 14px;
  border-radius: 16px;
  border: none;
  text-align: center;
}
.contact_text h4 span {
  color: #805dcd;
  font-weight: 700;
  font-size: 50px;
}
.contact_text h4 {
  font-size: 50px;
}
.contact_text p {
  margin: 20px 0px;
  font-size: 18px;
  color: #696969;
}
